import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useEffect } from "react";
import Layout from "../Layout";
import MemberCard from "./MemberCard";

import "./style.scss";

const teamdata = [
  {
    name: "Nitya Balagopal",
    imgSrc: "",
    designation: "Founder & MD",
    bio:
      "Magna nisi do duis commodo incididunt anim cillum aute. Est ipsum aliquip fugiat dolore do enim consectetur minim culpa ad. Et elit consequat aute reprehenderit irure labore laboris.",
  },
  {
    name: "Govind Sharma",
    imgSrc: "",
    designation: "Senior Designer",
    bio: "",
  },
  {
    name: "Hemant Pundeer",
    imgSrc: "",
    designation: "Studio Engineer",
    bio: "",
  },
  {
    name: "Himanshu Varshney",
    imgSrc: "",
    designation: "Web App Developer",
    bio: "",
  },
  {
    name: "Rahul Jangid",
    imgSrc: "",
    designation: "Visualizer",
    bio: "",
  },
  // {
  //   name: "Aditi Gautam",
  //   imgSrc: "",
  //   designation: "Marketing Associate",
  //   bio: "",
  // },
];

const alumniData = [
  {
    name: "Mukesh Kumar",
    imgSrc: "",
    designation: "Senior Designer",
    bio: "",
  },
  {
    name: "Sunny Sen",
    imgSrc: "",
    designation: "Senior Web App Developer",
    bio: "",
  },
  {
    name: "Durgesh Sable",
    imgSrc: "",
    designation: "Web App Developer",
    bio: "",
  },
];

export default function AboutPage() {
  const ref1 = React.useRef(null);

  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    const cursor = document.querySelector(".layout__cursor");
    cursor.classList.add("layout__cursor--dark");

    const topSection = document.querySelector(".about__top");

    const slider1 = document.querySelector("#team");
    let isDown1 = false;
    let startX1;
    let scrollLeft1;

    gsap.to(".layout", {
      scrollTrigger: {
        trigger: ref1.current,
        start: "top center",
        end: "100% center",

        toggleClass: { targets: ".layout", className: "layout--bgblack" },
        // markers: true,
      },
      ease: "linear",
      duration: 0.3,
    });

    function onMouseDown1(e) {
      isDown1 = true;
      slider1.classList.add("active");
      startX1 = e.pageX - slider1.offsetLeft;
      scrollLeft1 = slider1.scrollLeft;
      cursor.classList.add("layout__cursor--drag");
    }

    function onMouseLeave1(e) {
      isDown1 = false;
      slider1.classList.remove("active");
      cursor.classList.remove("layout__cursor--drag");
    }

    function onMouseUp1(e) {
      isDown1 = false;
      slider1.classList.remove("active");
      cursor.classList.remove("layout__cursor--drag");
    }

    function onMouseMove1(e) {
      if (!isDown1) return;
      e.preventDefault();
      const x = e.pageX - slider1.offsetLeft;
      const walk = (x - startX1) * 0.8; //scroll-fast
      slider1.scrollLeft = scrollLeft1 - walk;
    }

    function onMouseEnterTopSection() {
      cursor.classList.add("layout__cursor--dark");
    }
    function onMouseLeaveTopSection() {
      cursor.classList.remove("layout__cursor--dark");
    }

    // slider1.addEventListener("mousedown", onMouseDown1);
    // slider1.addEventListener("mouseleave", onMouseLeave1);
    // slider1.addEventListener("mouseup", onMouseUp1);
    // slider1.addEventListener("mousemove", onMouseMove1);

    topSection.addEventListener("mouseenter", onMouseEnterTopSection);
    topSection.addEventListener("mouseleave", onMouseLeaveTopSection);

    return () => {
      //   cursor.classList.remove("layout__cursor--dark");
      slider1.removeEventListener("mousedown", onMouseDown1);
      slider1.removeEventListener("mouseleave", onMouseLeave1);
      slider1.removeEventListener("mouseup", onMouseUp1);
      slider1.removeEventListener("mousemove", onMouseMove1);

      topSection.removeEventListener("mouseenter", onMouseEnterTopSection);
      topSection.removeEventListener("mouseleave", onMouseLeaveTopSection);
    };
  }, []);
  return (
    <Layout>
      <div className="about">
        <div ref={ref1} className="about__top">
          <h1 className="about__heading">Legacy</h1>

          <div className="about__toptext">
            As the digital vertical of{" "}
            <strong>Copy Desk Creative Services</strong>, a 30-year old media
            management firm renowned for excellence and integrity, Black
            Elephant Digital is taking long term associations to the platforms
            of the future.
            <br />
            <strong>Black Elephant Digital</strong> offers bespoke digital
            marketing services and completes the 360&#176; media bouquet offered
            by the <strong>CDCS Group</strong>.
          </div>

          <div className="about__middletext">
            {/* Commodo adipisicing laborum do incididunt ut nisi minim irure sunt
            laboris ea. Magna magna quis magna mollit consequat deserunt
            consectetur voluptate consequat tempor incididunt tempor sit.
            Adipisicing minim laborum minim magna exercitation officia tempor
            nisi. Incididunt velit magna id officia voluptate nisi sunt sit
            sint. */}
          </div>
        </div>

        <div className="about__bottom">
          <div className="about__team moveupanim1">
            <h3>Meet Our Team</h3>
            {/* <div className="about__team__dragtext">Hold and Drag</div> */}
            <div id="team" className="about__team__container">
              {teamdata.map((member, i) => (
                <MemberCard member={member} key={i} k={i + 1} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
