import React, { useEffect, useState } from "react";
import "./style.scss";
import { gsap } from "gsap";

export default function MemberCard({ member, k }) {
  useEffect(() => {
    const cursor = document.querySelector(".layout__cursor");

    const card = document.getElementById(`membercard${k}-pic`);

    var xPercent = 0;
    var yPercent = 0;

    card.addEventListener("mouseenter", toggleMouseEnter);
    card.addEventListener("mousemove", onMouseMoveGsap);
    card.addEventListener("mouseleave", onMouseLeaveGsap);

    function onMouseMoveGsap(e) {
      var rect = e.target.getBoundingClientRect();
      var x = e.clientX - rect.left; //x position within the element.
      var y = e.clientY - rect.top; //y position within the element.

      xPercent = (x / card.clientWidth) * 100;
      yPercent = (y / card.clientHeight) * 100;

      gsap.to(`#membercard${k}-pic .membercard__pic__front`, {
        clipPath: `circle(30% at ${xPercent}% ${yPercent}%`,
        duration: 0.1,
      });
    }

    function onMouseLeaveGsap(e) {
      gsap.to(`#membercard${k}-pic .membercard__pic__front`, {
        clipPath: `circle(0% at ${xPercent}% ${yPercent}%`,
        duration: 0.1,
      });
      toggleMouseLeave();
    }

    let toggle = document.querySelector(`#membercard${k}-toggle`);

    toggle.addEventListener("mouseenter", toggleMouseEnter);
    toggle.addEventListener("mouseleave", toggleMouseLeave);

    function toggleMouseEnter(e) {
      cursor.classList.add("layout__cursor--hide");
    }

    function toggleMouseLeave(e) {
      cursor.classList.remove("layout__cursor--hide");
    }

    return () => {
      card.removeEventListener("mousemove", onMouseMoveGsap);
      card.removeEventListener("mouseleave", onMouseLeaveGsap);
      toggle.removeEventListener("mouseenter", toggleMouseEnter);
      toggle.removeEventListener("mouseleave", toggleMouseLeave);
    };
  }, [k]);

  const [toggle, setToggle] = useState(false);
  return (
    <div id={`membercard${k}`} className="membercard">
      <div className="membercard__pic" id={`membercard${k}-pic`}>
        <img
          alt="member color"
          className="membercard__pic__front"
          src="https://picsum.photos/400/400/"
        />
        <img
          alt="member grayscale"
          className="membercard__pic__back"
          src="https://picsum.photos/400/400/"
        />
      </div>

      <div className="membercard__name">{member.name}</div>
      <div className="membercard__designation">{member.designation}</div>

      <div
        id={`membercard${k}-toggle`}
        className={`membercard__toggle  ${
          toggle && "membercard__toggle--active"
        }`}
        onClick={() => {
          setToggle(!toggle);
        }}
        onKeyDown={() => {}}
        role="button"
        tabIndex={k}
      >
        <div className="membercard__toggle__line1"></div>
        <div className="membercard__toggle__line2"></div>
      </div>

      <div className={`membercard__bio ${toggle && "membercard__bio--active"}`}>
        Sit veniam Lorem exercitation anim do eiusmod. Exercitation do nostrud
        non occaecat est. Adipisicing quis officia veniam amet consectetur
        consectetur commodo. Sint nostrud adipisicing ex reprehenderit sint enim
        aute.
      </div>
    </div>
  );
}
